import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Adolescents from '../../components/Relationships/components/Adolescents';

export default () => (
  <>
    <SEO
      title="Adolescents | Mindset Family Therapy"
      description="Adolescents are searching for a sense of self and personal identity. They need opportunities to feel competent and confident. Whether your adolescent is facing social, academic, emotional, mental or behavioral challenges, we are professionally trained to help your teen find a sense of self and feel confident in his/her life."
      pathname="/relationships/adolescents"
      article
    />
    <Layout>
      <Adolescents />
    </Layout>
  </>
);
