import _ from 'lodash';
import React from 'react';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import data from '../../../../data/adolescents';

import styles from './adolescents.module.scss';

const Adolescents = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497222290/playTherapyBG.jpg';
  const image1 = 'https://res.cloudinary.com/dewd4pral/image/upload/v1648592520/sunset_people.jpg';
  const image2 = 'https://res.cloudinary.com/dewd4pral/image/upload/v1648592371/adolescents1.jpg';

  const modalities = _.map(data.modalities, (m, index) => {
    return <li key={`${m}_${index}`} dangerouslySetInnerHTML={{ __html: m }} />;
  });

  const outcomes = _.map(data.outcomes, (o, index) => {
    return <li key={`${o}_${index}`} dangerouslySetInnerHTML={{ __html: o }} />;
  });

  return (
    <main>
      <PageHeader pageCategory="Relationships" pageName="Adolescents" headerImage={background} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro }} />
      </div>

      <QuoteImage image={image1} maxWidth={400} quote={data.quote1} />

      <div className={styles.textContainer}>
        <h2 className={styles.headerSmall}>
          How can Mindset Family Therapy help you and your teen?
        </h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro2 }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <ul style={{ marginTop: '-10px' }}>{modalities}</ul>
      </div>

      <QuoteImage image={image2} maxWidth={550} quote={data.quote2} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.outcomesIntro }} />
        <ul>{outcomes}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.close }} />
      </div>
    </main>
  );
};

export default Adolescents;
