const adolescents = {
  quote1: '<p>"It takes courage to grow up and become who you really are.”</p><p>E.E CUMMIINGS</p>',
  quote2:
    '<p>Though one may conquer a thousand times a thousand men in battle, yet he indeed is the noblest victor who conquers himself.”</p><p>BUDDHA</p>',
  intro:
    'Is your teenager struggling on their path towards adulthood? Do you know how your teen is perceiving her/himself? Are they feeling accepted and loved? How are you and those around them responding to their hardships?<br><br>Do you remember your teenage years? Did you experience insecurities and confusion about who you were and who you wanted to become? Did you struggle with a desire to “fit in” and be accepted by others?<br><br>Your adolescent is most likely experiencing similar uncertainties. Perhaps your transition from childhood to adulthood was a smooth one. Lucky you. There are many others who would rather have skipped that stage of their lives. None of us can escape it, and fortunately, most of us make it out just fine.<br><br>If you are reading this page, we know you are a caring parent who wants the best for your teen. Indeed, there are many ways your adolescent can feel successful despite the typical changes (e.g., physical, cognitive, emotional, and social) occurring in their lives.<br><br>According to Erik Erikson’s stages of psychosocial developmental, adolescents (12-18) experience a basic conflict: identity vs. role confusion. Teens are preoccupied with social relationships and their identity. They yearn to answer this basic question, “Who Am I and where am I going?”<br><br>Adolescents are searching for a sense of self and personal identity. They need opportunities to feel competent and confident. However, sometimes their all-or-nothing thinking can lead them to feel confused and weak when they fail in their endeavors. And when painful emotional and mental health struggles (e.g., anxiety, depression, obsessive-compulsive disorder, PTSD, ADHD) occur, life is extra difficult, not only for them, but for the rest of the family.',
  intro2:
    'Whether your adolescent is facing social, academic, emotional, mental or behavioral challenges, we are professionally trained to help your teen find a sense of self and feel confident in his/her life.<br><br>We are trained in the following psychotherapy modalities:',
  modalities: [
    'Acceptance & Commitment Therapy (ACT)',
    'Child-Parent Relationship Therapy (CPR-T)',
    'Cognitive Behavioral Therapy (CBT)',
    'Dialectical Behavioral Therapy (DBT)',
    'Exposure and Response Prevention (ERP)',
    'Eye Movement Desensitization and Reprocessing (EMDR)',
    'Solution-Focused Therapy',
    'Structural and Attachment theory models',
    'Trauma-Focused Cognitive Behavioral Therapy',
  ],
  outcomesIntro:
    'Teens can learn skills to navigate life’s adversities. As they apply these skills, they will be able to:<br><br>',
  outcomes: [
    'Recognize how their minds work.',
    'Discover their strengths and values, and live them every day.',
    'Change their relationship with their thoughts, feelings and other internal events (e.g., memories, evaluations, images, sensations, and urges).    ',
    'Respond to difficult thoughts and other internal experiences with flexibility instead of getting stuck with them.',
    'Understand that they <strong>have a choice</strong> on how to respond to their difficult internal and external experiences.',
    'Let go of the urge to fight, suppress, control, or avoid painful internal or external events.',
    'Connect with the here and now without having to become absorbed in public or private unhelpful behaviors.',
    'Develop self-compassion in the presence of difficulties.',
    'Focus on long-term responses to find meaning in their lives as opposed to the short-term strategies they are utilizing to no avail.',
    'Willingly participate in activities that bring them purpose and joy even in the presence of unpleasant thoughts and feelings.',
    'Engage in what is worth their focus, time, and energy to live with vitality.',
    'Become open, aware, and actively engaged in what matters most to them.',
  ],
  close:
    'At Mindset Family Therapy, we believe that parents are an essential part of their teen’s treatment. Together, we can build and strengthen your adolescent&apos;s confidence, resilience, self-compassion, and vision for their future, regardless of the mental health challenges (e.g., ADHD, OCD, PTSD, anxiety, depression, behavioral issues) they may be struggling with at this time.<br></br>Please call us at 801-427-1054 for questions, and to schedule an appointment.',
};

export default adolescents;
